import { HttpHeaders, type HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';

import { type ITaskInfo } from '@clover/task-info/task-info.model';

import { type TaskHistoryEvent } from './models/task-history-event';
import { HttpService } from '../core/services/http.service';

export interface ITaskLinkResponse {
  token: string;
}

@Injectable({
  providedIn: 'root',
})
export class TasksHistoryService {
  private readonly http = inject(HttpService);

  public async getTaskHistory(
    taskId: number,
    limit: number,
    offset: number,
  ): Promise<{ total: number; data: TaskHistoryEvent[] }> {
    return await this.http.get(`api/tasks/${taskId}/events?limit=${limit}&offset=${offset}`);
  }

  generateTaskLink(id: number): Observable<ITaskLinkResponse> {
    return this.http.postV2<ITaskLinkResponse>(`api/tasks/${id}/share`, {});
  }

  getTaskDetailsByToken(id: number, token: string): Observable<ITaskInfo> {
    return this.http.getV2<ITaskInfo>(`api/tasks/${id}/results?token=${token}`);
  }

  downloadTaskResults(id: number, token: string): Observable<any> {
    const url = `api/tasks/${id}/results/csv/download?token=${token}`;
    const headers = new HttpHeaders({
      'Content-Type': 'text/csv',
      Accept: 'text/csv',
    });
    return this.http.getV2(url, headers, {} as HttpParams, 'blob');
  }
}
